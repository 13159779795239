/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CartItem } from '../models/CartItem';
import type { Delivery } from '../models/Delivery';
import type { GoogleAnalyticsProductDetail } from '../models/GoogleAnalyticsProductDetail';
import type { PaymentMethod } from '../models/PaymentMethod';
import type { Product } from '../models/Product';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CartService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Share cart by link so someone can create the same one
     * @returns any Succesfully shared cart
     * @throws ApiError
     */
    public shareByLink({
        requestBody,
    }: {
        requestBody?: any,
    }): CancelablePromise<{
        /**
         * Link to click to destroy your own cart and create new one from saved
         */
        shareLink: string;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/front-api/v1/cart/share',
            body: requestBody,
        });
    }

    /**
     * Add products to cart
     * @returns any Item(s) were added or updated to cart
     * @throws ApiError
     */
    public addToCart({
        requestBody,
    }: {
        /**
         * Adds item to cart
         */
        requestBody?: {
            forceWarehouseId?: string | null;
            items?: Array<{
                /**
                 * Product ID
                 */
                productId?: number;
                /**
                 * How many units to add to cart
                 */
                quantity?: number;
            }>;
            /**
             * Where the product was added to cart from
             */
            addedFrom?: 1 | 2 | 3 | 4;
            /**
             * Text displayed on the button that was used to add to cart
             */
            buttonCopy?: string;
            /**
             * Custom names passed depending on the view that was used to add to cart
             */
            buttonType?: string;
            /**
             * Custom page names passed depending on the view that was used to add to cart
             */
            pageType?: string;
        },
    }): CancelablePromise<{
        items: Array<CartItem>;
        crossSellProducts: Array<Product>;
        delivery?: Delivery | null;
        payment?: PaymentMethod | null;
        /**
         * Total value of items in cart
         */
        total: number;
        /**
         * Total count of items in cart
         */
        count: number;
        /**
         * Google Analytics Products Details
         */
        googleAnalyticsProductsDetails: Array<GoogleAnalyticsProductDetail>;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/front-api/v1/cart',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Failed to add products to cart`,
                422: `Request Validation Failed`,
            },
        });
    }

    /**
     * @returns any Item was removed from cart
     * @throws ApiError
     */
    public removeFromCart({
        requestBody,
    }: {
        requestBody?: {
            itemId?: string;
        },
    }): CancelablePromise<{
        cartItems?: Array<CartItem>;
        products?: Array<Product>;
        delivery?: Delivery;
        payment?: PaymentMethod;
        /**
         * Count of items remaining in cart
         */
        count?: number;
        /**
         * Total value of items in cart
         */
        total?: number;
        crossSellProducts?: Array<Product>;
    }> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/front-api/v1/cart',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Request Validation Failed`,
            },
        });
    }

    /**
     * Update items quantities in cart
     * @returns any Item(s) were added or updated to cart
     * @throws ApiError
     */
    public updateQuantity({
        requestBody,
    }: {
        /**
         * Updates quantities of items in cart
         */
        requestBody?: {
            items?: Array<{
                /**
                 * Item ID in cart
                 */
                itemId?: string;
                /**
                 * Product ID
                 */
                productId?: number;
                /**
                 * How many units to add to cart
                 */
                quantity?: number;
            }>;
        },
    }): CancelablePromise<{
        items: Array<CartItem>;
        crossSellProducts: Array<Product>;
        delivery?: Delivery | null;
        payment?: PaymentMethod | null;
        /**
         * Total value of items in cart
         */
        total: number;
        /**
         * Total count of items in cart
         */
        count: number;
        /**
         * Google Analytics Products Details
         */
        googleAnalyticsProductsDetails: Array<GoogleAnalyticsProductDetail>;
    }> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/front-api/v1/cart',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Request Validation Failed`,
            },
        });
    }

}
