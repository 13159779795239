/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DeliverySubmethod = {
    /**
     * DeliverySubmethod constructor.
     */
    id?: number;
    /**
     * DeliverySubmethod constructor.
     */
    label?: string;
    /**
     * DeliverySubmethod constructor.
     */
    brandKey?: DeliverySubmethod.brandKey;
    /**
     * DeliverySubmethod constructor.
     */
    isActive?: boolean;
    /**
     * DeliverySubmethod constructor.
     */
    cost?: number;
    /**
     * Position in list
     */
    order?: number;
};

export namespace DeliverySubmethod {

    /**
     * DeliverySubmethod constructor.
     */
    export enum brandKey {
        POCZTA_POLSKA_ORLEN = 'POCZTA_POLSKA_ORLEN',
        POCZTA_POLSKA_ZABKA = 'POCZTA_POLSKA_ZABKA',
        POCZTA_POLSKA_AUTOMAT_BIEDRONKA = 'POCZTA_POLSKA_AUTOMAT_BIEDRONKA',
        POCZTA_POLSKA_AUTOMAT_PLACOWKA = 'POCZTA_POLSKA_AUTOMAT_PLACOWKA',
        POCZTA_POLSKA_AUTOMAT_CARREFOUR = 'POCZTA_POLSKA_AUTOMAT_CARREFOUR',
        POCZTA_POLSKA_AUTOMAT_SPOLEM = 'POCZTA_POLSKA_AUTOMAT_SPOLEM',
        POCZTA_POLSKA_LEWIATAN = 'POCZTA_POLSKA_LEWIATAN',
        POCZTA_POLSKA_AUTOMAT_LEWIATAN = 'POCZTA_POLSKA_AUTOMAT_LEWIATAN',
        POCZTA_POLSKA_ABC = 'POCZTA_POLSKA_ABC',
        POCZTA_POLSKA_DELIKATESY_CENTRUM = 'POCZTA_POLSKA_DELIKATESY_CENTRUM',
        POCZTA_POLSKA_RUCH = 'POCZTA_POLSKA_RUCH',
        POCZTA_POLSKA = 'POCZTA_POLSKA',
        PACZKA_W_RUCHU = 'PACZKA_W_RUCHU',
        PACZKOMAT = 'PACZKOMAT',
        DPD_POINT = 'DPD_POINT',
        POCZTA_POLSKA_ARCHELAN = 'POCZTA_POLSKA_ARCHELAN',
        POCZTA_POLSKA_GORSZEK = 'POCZTA_POLSKA_GORSZEK',
    }


}

