import { ComputedRef, Ref, computed, onMounted, ref } from 'vue';
import { UICartUpdateProductEvent, UICartUpdateQuantityEvent } from '../types/event';
import {
  CartItem,
  Delivery,
  GoogleAnalyticsProductDetail,
  PaymentMethod,
  Product,
} from '@/js/api/generated';
import isCsr from '../utils/isCsr';
import { frontApi } from '@/js/api/useFrontendApi';
import createProductUserHistory from '@/js/history/createProductUserHistory';
import UserHistoryRequest from '@/js/interface/UserHistoryRequest';
import { GoogleTagEvent } from '@/typings/google-tag-manager';
import { GoogleAnalyticsItem } from '@/typings/google-analytics';
import { GoogleUniversalAnalyticsItem } from '@/typings/google-universal-analytics';
import useModal from './useModal';
import { UIAlertModalTypeEnum } from '../components/ui-alert-modal/types';
import useWebcomponent from './useWebcomponent';
import useLoader from './useLoader';
import {
  UIAddedToCartModalPayload,
  UIAddedToCartModalProduct,
} from '../components/ui-added-to-cart-modal/types';
import { UICartInitialPayload } from '@/ui-kit/js/types/cart';

type UICurrentDeliveryType = {
  type: 'selected' | 'cheapest';
  cost: number;
}
export interface UIUseCart {
  total: Ref<number>,
  count: Ref<number>,
  cheapestDeliveryCost: Ref<number|null>,
  minFreeDeliveryThreshold: Ref<number|null>,
  selectedDelivery: Ref<Delivery|null|undefined>,
  freeShippingFrom: Ref<number | null | undefined>;
  freeShippingFromForSelectedViews: Ref<number | null | undefined>;
  freeShippingPercent: ComputedRef<number>;
  missingForFreeShipping: ComputedRef<number | null>;
  missingForFreeShippingForSelectedViews: Ref<number | null>;
  currentDeliveryCost: ComputedRef<UICurrentDeliveryType | null>;
  addToCart: (payload: UICartUpdateProductEvent) => Promise<void>;
  updateQuantity: (payload: UICartUpdateQuantityEvent) =>
    Promise<UICartUpdateQuantityResponse>;
  handleCart: () => void;
  priceRound: (price: number) => number;
}

export enum UICartUpdateEventSource {
  Cart = 'cart',
  UseCart = 'useCart',
  InpostPay = 'inpostPay',
}

type UICartUpdateQuantityResponse = {
  crossSellProducts?: Product[];
  quantity?: number;
}

export type UICartUpdateEvent = {
  cartTotal: number;
  cartCount: number;
  source: UICartUpdateEventSource,
};

const DELIVERY_PERSONAL_COLLECTION_SHIPPING_ID = 1;

function createEventsData (res: {
  items?: Array<CartItem>;
  crossSellProducts?: Array<Product>;
  delivery?: Delivery | null;
  payment?: PaymentMethod | null;
  total?: number;
  count?: number;
  googleAnalyticsProductsDetails?: Array<GoogleAnalyticsProductDetail>
}): {
  uaItems: GoogleUniversalAnalyticsItem[]
  g4Items: GoogleAnalyticsItem[],
  contentIds: number[]
} {
  const uaItems: Array<GoogleUniversalAnalyticsItem> = [];
  const g4Items: Array<GoogleAnalyticsItem> = [];
  const contentIds: Array<number> = [];

  res.items?.forEach((item) => {
    if (!item.product || !item.item) return;

    contentIds.push(item.product.productId);
    uaItems.push({
      id: item.product.productId.toString(),
      name: item.product.name,
      quantity: item.item.quantity,
      price: item.item.price,
    } as GoogleUniversalAnalyticsItem);

    g4Items.push({
      item_id: item.product.productId.toString(),
      item_name: item.product.name,
      quantity: item.item.quantity,
      price: item.item.price,
    } as GoogleAnalyticsItem);
  });
  return {uaItems, g4Items, contentIds};
}

export default function useCart (initialPayload?: UICartInitialPayload): UIUseCart {
  const {
    toggleModal,
    showAlert,
  } = useModal();
  const loader = useLoader();

  const total = ref(0);
  const count = ref(0);

  const cheapestDeliveryCost = ref(isCsr && window.uiCheapestDeliveryCost ?
    window.uiCheapestDeliveryCost :
    null,
  );

  const minFreeDeliveryThreshold = ref(isCsr && window.uiFreeDeliveryCalc ?
    window.uiFreeDeliveryCalc?.minFreeDeliveryThreshold :
    initialPayload?.freeDeliveryCalc?.minFreeDeliveryThreshold || null,
  );

  const selectedDelivery = ref<Delivery | null | undefined>(isCsr ?
    window.uiCartSelectedDelivery :
    initialPayload?.cartSelectedDelivery || null,
  );

  const freeShippingFrom = ref(
    (
      selectedDelivery.value &&
      selectedDelivery.value.shippingId !== DELIVERY_PERSONAL_COLLECTION_SHIPPING_ID
    ) ?
      selectedDelivery.value?.freeShipping as number :
      minFreeDeliveryThreshold,
  );

  const freeShippingForSelectedViews = computed(
    () => isCsr && window.uiCartSelectedDelivery?.freeShippingForSelectedViews ?
      window.uiCartSelectedDelivery?.freeShippingForSelectedViews :
      initialPayload?.cartSelectedDelivery?.freeShippingForSelectedViews || null,
  );

  const minFreeDeliveryThresholdForSelectedViews = computed(
    () => isCsr && window.uiFreeDeliveryCalc?.minFreeDeliveryThresholdForSelectedViews ?
      window.uiFreeDeliveryCalc?.minFreeDeliveryThresholdForSelectedViews :
      initialPayload?.freeDeliveryCalc?.minFreeDeliveryThresholdForSelectedViews || null,
  );

  const freeShippingFromForSelectedViews = computed(
    () => freeShippingForSelectedViews.value ||
      minFreeDeliveryThresholdForSelectedViews.value || null,
  );

  const freeShippingPercent = computed(
    () => {
      if (typeof freeShippingFrom.value !== 'number') {
        return 0;
      }

      return Math.min((
        (total.value ?? 0) * 100
      ) / freeShippingFrom.value, 100)
    },
  );

  const missingForFreeShipping = computed<number | null>(
    () => freeShippingFrom.value ?
      Math.max(priceRound(freeShippingFrom.value - (total.value ?? 0)), 0) :
      null,
  );


  const missingForFreeShippingForSelectedViews = computed<number | null>(
    () => freeShippingFromForSelectedViews.value ?
      Math.max(priceRound(freeShippingFromForSelectedViews.value - (total.value ?? 0)), 0) :
      null,
  );


  const currentDeliveryCost = computed<UICurrentDeliveryType | null>(
    () => {
      return {
        type: selectedDelivery.value ? 'selected' : 'cheapest',
        cost: selectedDelivery.value ?
          selectedDelivery.value.cost :
          (
            cheapestDeliveryCost.value ?
              cheapestDeliveryCost.value :
              0
          ),
      }
    },
  );

  const addToCart = async (
    payload: UICartUpdateProductEvent,
  ): Promise<void> => {
    try {
      loader.show();

      const pageType = (
        document.querySelector('body') as HTMLElement
      ).dataset.pageType as string;
      const res = await frontApi.cart.addToCart({ requestBody: {
        items: payload.items.map((item) => ({
          productId: item.id,
          quantity: item.quantity,
        })),
        addedFrom: payload.source,
        buttonCopy: payload.copy,
        buttonType: payload.type,
        pageType,
          forceWarehouseId: window.uiWarehouseId ? window.uiWarehouseId : null,
      }});

        const {uaItems, g4Items, contentIds} = createEventsData(res);
      res.googleAnalyticsProductsDetails && window.pushDataLayer({
        event: 'add_to_cart',
        ecommerce: {
          added_to_cart_items_g4: g4Items,
          added_to_cart_items_ua: uaItems,
          content_ids: contentIds,
          content_type: 'product',
          detail: {
            products: res.googleAnalyticsProductsDetails as Array<GoogleAnalyticsProductDetail>,
          },
        },
      } as GoogleTagEvent);

      const products: UIAddedToCartModalProduct[] = [];
      res.items?.forEach((item) => {
        if (!item.product || !item.item || !item.items) return;

        products.push({
          ...item.product,
          itemId: item.item.itemId,
          thumbImage: item.product.image?.thumbnailImage,
          price: item.item.price ?? 0,
          total: res.total,
          quantity: item.item.quantity ?? 1,
          prevQuantity: item.item.quantity ?? 1,
        });
      })
      const addedToCartModalPayload: UIAddedToCartModalPayload = {
        products: products,
        crossSellProducts: res.crossSellProducts,
      };
      if (!payload.disableModal) {
        await useWebcomponent('ui-added-to-cart-modal');
        toggleModal('addedToCartModal', addedToCartModalPayload, true);
      }

      count.value = res.count ?? 0;
      total.value = res.total ?? 0;
      document.dispatchEvent(new CustomEvent<UICartUpdateEvent>('cart_update', {
        detail: {
          cartCount: count.value,
          cartTotal: total.value,
          source: UICartUpdateEventSource.UseCart,
        },
      }));
      try {
        // TODO: Should be moved to frontApi or let backend handle it
        res.items && createProductUserHistory(
          {
            products: res.items.map((item) => ({
              productId: item.product?.productId,
            })),
            email: null,
            orderId: null,
          } as UserHistoryRequest,
          'addToBasket',
        ).then().catch((reason) => {
          throw Error(`Unknown create product user history error: ${(reason as Error).toString()}`)
        });
      } catch (e) {
        console.error(e);
      }
    } catch (e) {
      console.error(e);
      await showAlert({
        heading: 'Wystąpił błąd',
        type: UIAlertModalTypeEnum.Error,
        content: 'Nie udało się dodać produktu do koszyka. Spróbuj ponownie za chwilę.',
      });
    } finally {
      loader.hide();
    }
  }

  const updateQuantity = async (
    payload: UICartUpdateQuantityEvent,
  ): Promise<UICartUpdateQuantityResponse> => {

    try {
      loader.show();

      const res = await frontApi.cart.updateQuantity({
        requestBody: {
          items: [payload],
        },
      });
      const {uaItems, g4Items, contentIds} = createEventsData(res);
      res.googleAnalyticsProductsDetails && window.pushDataLayer({
        event: payload.quantity > payload.prevQuantity ? 'add_to_cart' : 'remove_from_cart',
        ecommerce: {
          added_to_cart_items_g4: g4Items,
          added_to_cart_items_ua: uaItems,
          content_ids: contentIds,
          content_type: 'product',
          detail: {
            products: res.googleAnalyticsProductsDetails as Array<GoogleAnalyticsProductDetail>,
          },
        },
      } as GoogleTagEvent);

      toggleModal('addedToCartModal', {
        crossSellProducts: res.crossSellProducts,
      }, true);

      count.value = res.count ?? count.value;
      total.value = res.total ?? total.value;

      document.dispatchEvent(new CustomEvent<UICartUpdateEvent>('cart_update', {
        detail: {
          cartCount: count.value,
          cartTotal: total.value,
          source: UICartUpdateEventSource.UseCart,
        },
      }));

      return {
        crossSellProducts: res.crossSellProducts,
        quantity: res.items?.find(
          (item) => item.item?.itemId === payload.itemId,
        )?.item?.quantity,
      }
    } catch (e) {
      await showAlert({
        heading: 'Wystąpił błąd',
        type: UIAlertModalTypeEnum.Error,
        content: 'Nie udało się zmienić ilości produktu w koszyku.',
      });

      throw e;
    } finally {
      loader.hide();
    }
  }

  const priceRound = (price: number): number => Math.round(price * 100) / 100;

  const handleCart = (): void => {
    document.addEventListener('cart_add_product', async (event) => {
      const detail = (event as CustomEvent<UICartUpdateProductEvent>).detail;

      await addToCart(detail);
    });
  }

  isCsr && document.addEventListener('cart_update', (event) => {
    const cartTotal = (
      event as CustomEvent<UICartUpdateEvent>
    ).detail.cartTotal;
    count.value = (event as CustomEvent<UICartUpdateEvent>).detail.cartCount;
    if (cartTotal >= 0) {
      total.value = cartTotal;
    }
  });

  onMounted(() => {
    total.value = isCsr && window.uiCartTotal ? window.uiCartTotal : 0;
    count.value = isCsr && window.uiCartCount ? window.uiCartCount : 0;
  });

  return {
    total,
    count,
    freeShippingFrom,
    freeShippingFromForSelectedViews,
    freeShippingPercent,
    missingForFreeShipping,
    missingForFreeShippingForSelectedViews,
    currentDeliveryCost,
    cheapestDeliveryCost,
    minFreeDeliveryThreshold,
    selectedDelivery,
    addToCart,
    updateQuantity,
    handleCart,
    priceRound,
  }
}
