
import {computed, defineComponent, onMounted, onUnmounted, PropType, ref, watch} from 'vue';
import useUIKit from '@/ui-kit/js/composable/useUIKit';
import {SwiperOptions} from 'swiper11/types/swiper-options';
import useWebcomponent from '@/ui/composable/useWebcomponent';
import {Product} from '@/js/api/generated';
import usePlaceholder from '@/ui/composable/usePlaceholder';
import {SwiperContainer} from 'swiper11/swiper-element';
import {frontApi} from '@/api/useFrontendApi';
import {UIProductTupleProductLinkClicked} from '@/ui/components/ui-product-tuple/types';
import handleLandingPageEvent from '@/ui/utils/dataLayer/landingPageEvent';
import relativePathIfInternal from '@/ui/utils/relativePathIfInternal';
import item from '@/ui/utils/dataLayer/item';
import {emitViewSliderElement} from '@/js/swiper/swiper-event-emmiter';
import getActiveIndex from '@/js/swiper/getActiveIndex';
import productToGoogleEcommerceItem from '@/ui/utils/dataLayer/productToGoogleEcommerceItem';
import UIProductTuple from '@/ui/components/ui-product-tuple/UIProductTuple.vue';

export default defineComponent({
  name: 'UIProductSwiper',
  components: {
    UIProductTuple,
  },
  props: {
    products: {
      type: [String, Array] as PropType<Product[] | string>,
      default: [] as Product[],
    },
    horizontal: {
      type: Boolean,
      default: undefined,
    },
    showFavoriteButton: {
      type: Boolean,
      default: false,
    },
    hideCartButton: {
      type: Boolean,
      default: false,
    },
    showBadges: {
      type: Boolean,
      default: false,
    },
    options: {
      type: [String, Object] as PropType<SwiperOptions | string>,
      default: {},
    },
    size: {
      type: String,
      default: 'sm',
    },
    customUrl: {
      type: String,
      default: null,
      description: 'Base 64 encoded string',
    },
    productsUrl: {
      type: String,
      default: null,
    },
    usedExternal: {
      type: Boolean,
      default: false,
    },
    openBlank: {
      type: Boolean,
      default: false,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    productsLimit: {
      type: Number || undefined,
      default: undefined,
    },
    onlyWithImage: {
      type: Boolean,
      default: false,
    },
    landingPageLayoutName: {
      type: String || undefined,
      default: undefined,
    },
    appendSlide: {
      type: [String, Object] as PropType<{link: string, title: string} | string>,
      default: undefined,
    },
  },
  setup (props) {
    const component = ref<HTMLElement>();
    const arrowPrev = ref<HTMLElement>();
    const arrowNext = ref<HTMLElement>();

    const placeholder = usePlaceholder();

    const productsPayload = ref<Product[]>([]);

    const swiperOptionsPayload = computed<SwiperOptions | null>(
      () => typeof props.options === 'string' ?
        JSON.parse(props.options) as SwiperOptions :
        props.options,
    );
    const appendSlidePayload = computed<{title: string, link: string} | undefined>(
      () => typeof props.appendSlide === 'string' ?
        JSON.parse(props.appendSlide) :
        props.appendSlide,
    );

    const { viewItem, selectItem} = item();
    const swiperOptionsWithDefaults = computed(() => {
      const defaults = {
        'spaceBetween': 32,
        'slidesOffsetBefore': 0,
        'breakpoints': {
          0: {
            slidesPerView: 2.6,
          },
          768: {
            slidesPerView: 3.6,
          },
          1280: {
            slidesPerView: 4.6,
            slidesOffsetBefore: 44,
          },
          1920: {
            slidesPerView: 5.6,
          },
        },
      }

      return {...defaults, ...swiperOptionsPayload.value}
    })

    const updateProductsPayload = (newProducts) => {
      if (typeof newProducts === 'string') {
        productsPayload.value = JSON.parse(newProducts);
      } else {
        productsPayload.value = newProducts;
      }
    };

    watch(() => props.products, (newValue) => {
      updateProductsPayload(newValue);
    }, { immediate: true });

    function initSwiper (): void {
      const swiperEl: SwiperContainer = component.value?.querySelector(
        'swiper-container',
      ) as unknown as SwiperContainer;

      if (swiperEl) {
        Object.assign(swiperEl, {
          ...swiperOptionsWithDefaults.value,
          on: {
            async beforeInit () {
              if (!props.lazy) {
                if (props.productsUrl) {
                  await loadProductsFromProductApiByLinkToIt();
                } else if (props.customUrl) {
                  await loadProductsFromSearchByLinkApi();
                } else {
                  await loadProductsPayloadFromProps()
                }
              } else {
                if (props.productsUrl) {
                  observeIntersection(loadProductsFromProductApiByLinkToIt)
                } else if (props.customUrl) {
                  observeIntersection(loadProductsFromSearchByLinkApi)
                } else {
                  observeIntersection(loadProductsPayloadFromProps)
                }
              }
            },
            afterInit () {
              placeholder.init(component);
            },
          },
        });
        swiperEl?.initialize();
      }
    }

    const onProductLinkClicked =
      (event: CustomEvent<Array<UIProductTupleProductLinkClicked>>, index: number): void => {
        const product = event.detail[0] as unknown as UIProductTupleProductLinkClicked;

        selectItem(
          productToGoogleEcommerceItem(product, index),
          props.landingPageLayoutName ? `${props.landingPageLayoutName} -  LP - swiper produktowy` : undefined,
        );
      }

    const loadProductsFromProductApiByLinkToIt = async () => {
      const response = await fetch(props.productsUrl);
      productsPayload.value = await response.json() as unknown as Product[];
    }

    const loadProductsFromSearchByLinkApi = async () => {
      let link: string = props.customUrl ?? '';
      if (!props.customUrl.includes('https://') && !props.customUrl.startsWith('/')) {
        link = decodeURIComponent(window.atob(props.customUrl ?? '')) ?? '';
      }
      const hasQueryParameters = link.includes('?');
      const separator = hasQueryParameters ? '&' : '?';
      if (link) {
        if (props.productsLimit) {
          link = link + `${separator}showPerPage=${props.productsLimit}`;
        } else if (!link.includes('showPerPage')) {
          link = link + `${separator}showPerPage=36`;
        }
        if (props.onlyWithImage) {
          link += '&hasImage=1';
        }
        link = window.btoa(encodeURIComponent(link));
      }
      const res = await frontApi.landingPage.searchProductsByUrls({urlArray: [link]});
      if (res[link]) {
        productsPayload.value = res[link].products.map((product) => (product))
      }
    }

    const loadProductsPayloadFromProps = async () => {
      if (typeof props.products === 'string') {
        productsPayload.value = JSON.parse(props.products) as Product[]
      } else {
        productsPayload.value = props.products;
      }
    }

    const observeIntersection = (interactionAction: () => Promise<void>): void => {
      observer = new IntersectionObserver(
        async (entries) => {
          if (entries[0].isIntersecting && component.value) {
            await interactionAction();
            observer?.unobserve(component.value);
          }
        },
        {
          threshold: 0.1,
          root: null,
          rootMargin: '0px',
        },
      );
      if (component.value) {
        observer.observe(component.value);
      }
    }

    let observer: IntersectionObserver | undefined = undefined;

    onMounted(async () => {
      await useWebcomponent('ui-swiper');
      initSwiper();

      const {markAsLoaded} = await useUIKit(
        component,
        'ui-product-swiper',
        {
          usedExternal: props.usedExternal,
          loadImmediately: false,
        },
      );

      if (props.usedExternal) {
        await useWebcomponent('ui-badge');
        await useWebcomponent('ui-rating');
        await useWebcomponent('ui-price');
        await useWebcomponent('ui-button');
        await useWebcomponent('ui-product-tuple');
      }

      markAsLoaded();
    })

    onUnmounted(() => {
      if (component.value && observer !== undefined) {
        observer.unobserve(component.value);
      }
    });

    const productsPayloadComputed = computed((): Array<Product | null> => {
      if (productsPayload.value.length > 0) {
        return productsPayload.value.filter((product) => product.price >= 0.03);
      }

      return [null, null, null, null, null, null, null, null, null];
    });

    return {
      component,
      arrowPrev,
      arrowNext,
      productsPayload,
      swiperOptionsWithDefaults,
      placeholder,
      onProductLinkClicked,
      productsPayloadComputed,
      appendSlidePayload,
      relativePathIfInternal,
    }
  },
})
