
import {computed, ComputedRef, defineComponent, onMounted, PropType, ref, watch} from 'vue';
import useUIKit from '@/ui/composable/useUIKit';
import {UIProductTupleBadgeVariant, UIProductTupleProductLinkClicked} from '@/ui/components/ui-product-tuple/types';
import {AttributeProduct, Product, Publisher} from '@/api/generated';
import {UIProductTag} from '@/ui/types/productTags';
import {AuthorsIds} from '@/ui/types/authors';
import useBreakpoint from '@/ui/composable/useBreakpoint';
import useCart from '@/ui/composable/useCart';
import useModal from '@/ui/composable/useModal';
import usePlaceholder from '@/ui/composable/usePlaceholder';
import {UIAttributeProduct} from '@/ui/types/productAttributes';
import {badgeVariants} from '@/ui/components/ui-product-tuple/const';
import clickLoggerService from '@/ui/services/clickLogger';
import useLoader from '@/ui/composable/useLoader';
import {UIKitButtonSizeEnum} from '../ui-button/types';
import {UICartUpdateProductEvent} from '@/ui/types/event';


export default defineComponent({
  name: 'UIProductTuple',
  props: {
    product: {
      type: [String, Object] as PropType<string | Product>,
      default: null,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
    showFavoriteButton: {
      type: Boolean,
      default: false,
    },
    fallbackNotificationBtn: {
      type: Boolean,
      default: false,
    },
    showCartButton: {
      type: Boolean,
      default: false,
    },
    showBadges: {
      type: Boolean,
      default: false,
    },
    disableHref: {
      type: Boolean,
      default: false,
    },
    usedExternal: {
      type: Boolean,
      default: false,
    },
    openBlank: {
      type: Boolean,
      default: false,
    },
    listingHover: {
      type: Boolean,
      default: false,
    },
    estimatedShipping: {
      type: String,
      default: undefined,
    },
    disablePlaceholder: {
      type: Boolean,
      default: false,
    },
    bigImage: {
      type: Boolean,
      default: false,
    },
    promotion: {
      type: [String, Object] as PropType<UIProductTupleBadgeVariant | string>,
      default: null,
    },
    useClickLoggerQuery: {
      type: String,
      default: null,
    },
  },
  emits: ['productLinkClicked'],
  setup (props, {emit}) {
    const placeholder = usePlaceholder();
    const {
      isMobile,
    } = useBreakpoint();
    const {
      addToCart,
    } = useCart();
    const {toggleAsyncModal} = useModal();
    const loader = useLoader();

    const component = ref<HTMLElement>();
    const hostElement = ref<HTMLElement | null>(null);

    const hoverHightlighted = ref(false);


    const productPayload: ComputedRef<Product | null> = computed<Product | null>(() => {
      if (typeof props.product === 'string') {
        try {
          return JSON.parse(props.product) as Product;
        } catch {
          return null;
        }
      }
      return props.product;
    });


    const promotionPayload = computed<UIProductTupleBadgeVariant>(
      () => typeof props.promotion === 'string' ?
        JSON.parse(props.promotion) as UIProductTupleBadgeVariant :
        props.promotion,
    )

    const classes = computed(() => ({
      'ui-product-tuple': true,
      [`ui-product-tuple--size-${props.size}`]: true,
      'ui-product-tuple--horizontal': props.horizontal || props.inline,
      'ui-product-tuple--inline': props.inline,
    }));

    const attributes = computed(() => {
      const attrs = productPayload.value?.attributes ?? [];

      return {
        cover: findAttributeProductById(attrs, UIAttributeProduct.ATTRIBUTE_COVER),
        pageCount: findAttributeProductById(attrs, UIAttributeProduct.ATTRIBUTE_PAGE_COUNT),
        minAge: findAttributeProductById(
          attrs,
          UIAttributeProduct.ATTRIBUTE_MIN_PLAYER_AGE,
        ), // TODO: verify if its correct
        premiereDate: findAttributeProductById(attrs, UIAttributeProduct.ATTRIBUTE_PREMIERE_DATE),
        menApprovalNumber: findAttributeProductById(attrs, UIAttributeProduct.MEN_APPROVAL_NUMBER),
      }
    });

    const isAnnouncement = computed((): boolean => {
      const status = productPayload.value?.productAvailableStatus;
      if (!status) {
        return false;
      }

      return [
        Product.productAvailableStatus.PREDICTION,
        Product.productAvailableStatus.REALIZATION,
      ].includes(status);
    });

    const announcementDate = computed(() => {
      if (
        !productPayload.value ||
        !isAnnouncement.value ||
        !productPayload.value?.availableAt
      ) {
        return null;
      }
      const date = new Date(productPayload.value?.availableAt * 1000);

      return `${
        ('0' + date.getDate()).slice(-2)
      }.${
        ('0' + (date.getMonth() + 1)).slice(-2)
      }.${date.getFullYear()}`;
    });

    const badge = computed((): UIProductTupleBadgeVariant | null => {
      if (isAnnouncement.value) return badgeVariants.announcement;

      if (productPayload.value?.tags?.some(
        (tag) => tag.tagId as UIProductTag === UIProductTag.BESTSELLER,
      )) {
        return badgeVariants.top;
      }

      if (productPayload.value?.tags?.some(
        (tag) => tag.tagId === Number(UIProductTag.NOVELTY),
      )) {
        return badgeVariants.novelty;
      }

      if (promotionPayload.value) {
        return promotionPayload.value;
      }

      return null;
    });

    const publishers = computed(() => {
      if (!productPayload.value || !productPayload.value?.publishers) return [];

      return Object.values(productPayload.value?.publishers ?? []);
    });

    const coverAttribute = computed(
      () => attributes.value.cover?.attributeValue ? (
        isMobile.value ?
          attributes.value.cover.attributeValue.replace(/okładka /g, '') :
          `okładka ${attributes.value.cover.attributeValue.replace(/okładka/g, '')}`
      ) : undefined,
    );

    const findAttributeProductById = (
      attributeProducts: AttributeProduct[],
      attributeId: UIAttributeProduct,
    ): AttributeProduct | undefined => {
      return attributeProducts ?
        attributeProducts.find((attributeProduct) => attributeProduct.attributeId === attributeId) :
        undefined;
    };

    const addProductToFavorite = async (): Promise<void> => {
      if (!productPayload.value) return;

      if (props.usedExternal && productPayload.value?.path) {
        // Replace is used to change the path to relative for proper testing
        window.open(`${productPayload.value?.path}?uiAddToFavorite=1`, '_blank');
        return;
      }

      await toggleAsyncModal(
        'addToWishlistModal',
        'ui-wishlist-modal',
        productPayload.value?.productId,
      );
    };

    const handleAddToCart = async (): Promise<void> => {
      if (!productPayload.value || typeof productPayload.value?.price !== 'number') {
        return;
      }

      await addToCart({
        items: [{
          id: productPayload.value?.productId,
          // price: productPayload.value?.price || 0,
          quantity: 1,
        }],
        source: 2, // TODO: Enumed source
        type: 'Product Tuple Add To Cart',
      } as UICartUpdateProductEvent);
    };

    const handleTupleClick = async (event: Event): Promise<void> => {
      if (!productPayload.value) return;

      event.preventDefault();
      emit('productLinkClicked', productPayload)

      if (props.useClickLoggerQuery) {
        try {
          loader.show();

          await clickLoggerService.log({
            productId: productPayload.value?.productId ?? 0,
            query: props.useClickLoggerQuery,
          });
        } catch (e) {
          console.error(e);
        } finally {
          loader.hide();
        }
      }

      hostElement.value?.dispatchEvent(new CustomEvent('click'));

      if (!props.disableHref && productPayload.value?.path) {
        location.href = productPayload.value?.path ?? '';
      }
    }

    const handleHighlightedHover = (state: boolean): void => {
      if (!props.listingHover) return;

      hoverHightlighted.value = state;
    }

    const handleNotifyWhenProductAvailable = (): void => {
      document.dispatchEvent(new CustomEvent(
        'product_notify_when_available',
        {
          detail: {
            productId: productPayload.value ? productPayload.value?.productId : null,
          },
        },
      ));
    }

    onMounted(async () => {
      const {host} = await useUIKit(component, 'ui-product-tuple', {
        usedExternal: props.usedExternal,
        loadImmediately: false,
      });

      hostElement.value = host;

      if (productPayload.value !== null && !props.disablePlaceholder) {
        placeholder.init(component);
      }
    });

    watch(() => productPayload.value, () => {
      if (productPayload.value !== null && !props.disablePlaceholder) {
        placeholder.init(component);
      }
    });

    return {
      placeholder,
      component,
      classes,
      hoverHightlighted,
      productPayload,
      promotionPayload,
      badge,
      attributes,
      publishers,
      coverAttribute,
      isAnnouncement,
      announcementDate,
      addProductToFavorite,
      handleAddToCart,
      AuthorsIds,
      handleTupleClick,
      handleHighlightedHover,
      handleNotifyWhenProductAvailable,
      Product,
      UIKitButtonSizeEnum,
    }
  },
});
